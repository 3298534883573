
import {mapState} from "vuex";

export default {
  name: "SubMenuFolderList",
  props: ['leftMenu'],
  computed: {
    ...mapState({
      dossiers: (state) => {
        return state.header.subMenuItems.dossiers
      },
    })
  }
}
